<script lang="ts">
  export let progress: number
</script>

<div class="progress-bar">
  <div class="progress-bar__container">
    <div style="width: {progress}%" />
  </div>
</div>

<style>
  .progress-bar {
    width: 100%;
  }

  .progress-bar__container {
    background-color: var(--aiden-progress-bar-track-color, #999);
    border-radius: var(--aiden-progress-bar-border-radius, 0.75rem);
    overflow: hidden;
    position: relative;
    height: 0.5rem;
    width: 100%;
  }

  .progress-bar__container div {
    background-color: var(--aiden-progress-bar-color, #000);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    min-width: 30px;
    transition: 300ms width ease-out;
  }
</style>
