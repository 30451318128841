<div class="embedded-container">
  <slot />
</div>

<style>
  @keyframes shimmer {
    from {
      left: -150px;
    }
    to {
      left: 100%;
    }
  }

  .embedded-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    background-color: var(--aiden-shade3-color);
    overflow: hidden;
  }

  .embedded-container:before {
    content: "";
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(255, 255, 255, 0.35) 50%,
      transparent 100%
    );
    animation: shimmer 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
  .embedded-container :global(iframe) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
