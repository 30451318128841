import { get, writable, type Writable } from "svelte/store"

type FormState = {
  email: string
  emailValid: boolean
  displayEmailError: boolean
  checkboxChecked: boolean
}

export type FormStateStore = Writable<FormState> & {
  setEmail: (email: string, isValid: boolean) => void
  setCheckboxChecked: (checked: boolean) => void
  submit: () => boolean
}

export function createFormState(
  onSubmit: (email: string, checkboxChecked: boolean) => void
): FormStateStore {
  const store = writable<FormState>({
    email: "",
    emailValid: false,
    displayEmailError: false,
    checkboxChecked: false,
  })
  const { subscribe, set, update } = store

  return {
    subscribe,
    set,
    update,
    submit: () => {
      const state = get(store)
      if (state.emailValid) {
        onSubmit(state.email, state.checkboxChecked)
        return true
      }
      update((state) => {
        return { ...state, displayEmailError: true }
      })
      return false
    },
    setEmail: (email: string, isValid: boolean) => {
      update((state) => {
        return {
          ...state,
          email: email,
          emailValid: isValid,
          displayEmailError: false,
        }
      })
    },
    setCheckboxChecked: (checked: boolean) => {
      update((state) => {
        return { ...state, checkboxChecked: checked }
      })
    },
  }
}

// $formState.email
