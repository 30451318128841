import type { Product } from "./types"
import Drilldown from "../../Drilldown"
import type {
  AdvisedProduct,
  AidenRequest,
  AidenResponse,
  Attribute,
  Control,
  DrilldownOption,
  PdpCard,
  ProductCard,
  ProductCardPayload,
  ProductControl,
  ResultsControl,
} from "../../types"
import { getTranslation } from "../../lang/i18n"
import type { Recommendation } from "../../app/toPage"

export default {
  answersFromRequest: (
    req: AidenRequest,
    getData: (attributeId: string) => Attribute["value"]
  ): string[] => {
    if (req.screen.component !== "question") return []

    return [
      ...predefinedAnswers(req.screen.controls, getData),
      ...drilldownAnswers(req.screen.controls, getData),
      ...autoDrilldownAnswers(req.screen.controls, getData),
      ...numericAnswers(req.screen.controls, getData),
    ]
  },

  /**
   * Retrieve the question label from the request
   * */
  questionFromRequest: (req: AidenRequest): string | undefined => {
    if (req.screen.component !== "question") return
    return req.screen.controls.find(
      (ctrl) => typeof ctrl === "object" && "attributeInstanceId" in ctrl
    )?.label
  },

  /**
   * Retrieve the products label from the response
   * */
  advisedProductsFromResponse: (response: AidenResponse): Product[] => {
    const cardToProduct = (card: { payload: ProductCardPayload }): Product => {
      return {
        id: card.payload.productId,
        clientProductId: card.payload.clientProductId as string,
        // integrationData: card.payload.integrationData as string,
        name: card.payload.productName,
        link: card.payload.productUrl || "", // Optional because the button can be hidden
        image: card.payload.imageUrl,
        price: card.payload.price,
        data: card.payload.data as Record<string, unknown>, //TODO: Double check
      }
    }

    const pdpProduct: Product | undefined = response.screen.controls
      .filter(isPdpCard)
      .map((card) =>
        pdpCardIsAdvised(card) ? cardToProduct(card) : undefined
      )[0]

    const products = (
      response.screen.controls.find(isResultsControl)?.controls || []
    )
      .filter(isProductCard)
      .map(cardToProduct)

    return pdpProduct ? [...products, pdpProduct] : products
  },

  /**
   *
   */
  productControlsToAdvisedProducts: (
    controls: ProductControl[]
  ): AdvisedProduct[] =>
    controls.map((product) => {
      const salePriceForEvent: string | undefined = isSalePriceActive(
        product.payload.price as string | null,
        product.payload.salePrice as string | null,
        product.payload.salePeriodStart as number | null,
        product.payload.salePeriodEnd as number | null
      )
        ? (product.payload.salePrice as string) || undefined
        : undefined

      return {
        id: product.payload.clientProductId as string,
        name: product.payload.productName as string,
        url: product.payload.productUrl as string,
        imageUrl: product.payload.imageUrl as string,
        price: undefinedIfNotThere(product.payload.price),
        salePrice: salePriceForEvent,
        data: (product.payload.data as Record<string, any>) || {},
      }
    }),

  /**
   * Recommendations
   */
  recommendationsToAdvisedProducts: (
    recommendations: Recommendation[]
  ): AdvisedProduct[] =>
    recommendations.map((product) => {
      const salePriceForEvent: string | undefined = isSalePriceActive(
        product?.salePrice?.price || null,
        product?.price || null,
        product?.salePrice?.salePeriodStart || null,
        product?.salePrice?.salePeriodEnd || null
      )
        ? product?.salePrice?.price
        : undefined

      return {
        id: product.clientProductId as string,
        name: product.productName as string,
        url: product.productUrl as string,
        imageUrl: product.imageUrl as string,
        price: undefinedIfNotThere(product.price),
        salePrice: salePriceForEvent,
        data: (product.data as Record<string, any>) || {},
      }
    }),
}
// Implementation helpers

const isSalePriceActive = (
  price: string | null,
  salePrice: string | null,
  salePeriodStart: number | null,
  salePeriodEnd: number | null
) => {
  const now = Date.now()
  const saleIsNotTheSameAsPrice = salePrice && salePrice !== price

  if (salePeriodStart && salePeriodEnd && saleIsNotTheSameAsPrice) {
    return now >= salePeriodStart && now <= salePeriodEnd
  } else if (saleIsNotTheSameAsPrice) {
    return true
  } else {
    return false
  }
}

const undefinedIfNotThere = (a: any): string | undefined => {
  if (typeof a === "string") return a
  return undefined
}

type AnswerLabelCollector = (
  controls: Control[],
  getData: (attributeId: string) => Attribute["value"]
) => string[]

const predefinedAnswers: AnswerLabelCollector = (controls, getData) => {
  return controls
    .map(getSelectionOptions)
    .filter(
      (a): a is [{ label: string; value: string }[], string] => a !== undefined
    )
    .flatMap(([options, attributeId]) => {
      let d = getData(attributeId)

      if (!Array.isArray(d)) {
        if (typeof d === "number") {
          d = [`${d}`]
        } else if (typeof d === "string") {
          d = [d]
        } else {
          d = []
        }
      }

      return d
        .map(
          (value) =>
            options.find(
              (opt: { label: string; value: string }) => opt.value === value
            )?.label
        )
        .filter((a): a is string => a !== undefined)
    })
}

const numericAnswers: AnswerLabelCollector = (controls, getData) =>
  controls
    .filter((ctrl: Control) => ["number", "slider"].includes(ctrl.component))
    .flatMap((ctrl: Control) => {
      if ("attributeInstanceId" in ctrl) {
        let d = getData(ctrl.attributeInstanceId)
        if (!Array.isArray(d)) {
          if (typeof d === "number") {
            d = [`${d}`]
          } else if (typeof d === "string") {
            d = [d]
          } else {
            d = []
          }
        }
        return d
      } else {
        return []
      }
    })

const DRILLDOWN_ANSWERS_SEPERATOR = " - "

const drilldownAnswers: AnswerLabelCollector = (controls, getData) =>
  controls
    .map((ctrl: Control) => {
      if (
        typeof ctrl === "object" &&
        "drill-down" === ctrl.component &&
        "attributeInstanceId" in ctrl
      ) {
        const value = getData(ctrl.attributeInstanceId)
        const answerId = typeof value === "string" ? value : ""

        const answer = Drilldown.pathToAnswer(
          ctrl.payload.options as DrilldownOption[],
          answerId
        )
          .map((opt) => opt.title)
          .join(DRILLDOWN_ANSWERS_SEPERATOR)

        return answer
      }
    })
    .filter((a) => !!a)
    .map((v) => v as string)

const autoDrilldownAnswers: AnswerLabelCollector = (
  controls,
  getData
): string[] =>
  controls
    .map((ctrl: Control) => {
      if (
        typeof ctrl === "object" &&
        "auto-drill-down" === ctrl.component &&
        "attributeInstanceId" in ctrl
      ) {
        const values = getData(ctrl.attributeInstanceId)
        if (Array.isArray(values)) {
          if (values.length === 0) {
            return ""
          } else if (values.some((v) => v.startsWith("0:"))) {
            return handleAutoDrilldownValueAnswer(values)
          } else {
            return handleAutoDrilldownNeutralAnswer(values)
          }
        }
      }
      return ""
    })
    .filter((a) => !!a)

const splitLevelValue = (value: string): [number, string] | undefined => {
  const sections = value.split(":")
  const index = parseInt(sections[0])
  const val = sections.slice(1).join(":")
  if (isFinite(index) && index >= 0 && val.trim() !== "") {
    return [index, val]
  }
}

const isResultsControl = (obj: any): obj is ResultsControl =>
  typeof obj === "object" &&
  !!((obj as Record<string, any>)["id"] === "results") &&
  "controls" in obj

const isProductCard = (obj: any): obj is ProductCard =>
  typeof obj === "object" &&
  !!((obj as Record<string, any>)["component"] === "product-card")

const isPdpCard = (obj: any): obj is PdpCard =>
  typeof obj === "object" &&
  (!!((obj as Record<string, any>)["component"] === "pdp-card") ||
    !!((obj as Record<string, any>)["component"] === " pdp-item"))

const getSelectionOptions = (
  ctrl: Control
): [{ label: string; value: string }[], string] | undefined => {
  if (
    typeof ctrl === "object" &&
    "options" in ctrl &&
    "attributeInstanceId" in ctrl
  ) {
    return [ctrl.options, ctrl.attributeInstanceId]
  }
}
const handleAutoDrilldownValueAnswer = (values: string[]): string =>
  values
    .map(splitLevelValue)
    .filter((v) => !!v)
    .map((v) => v as [number, string])
    .sort((a, b) => a[0] - b[0])
    .map((v) => v[1])
    .join(DRILLDOWN_ANSWERS_SEPERATOR)

const handleAutoDrilldownNeutralAnswer = (values: string[]): string => {
  return values[0] !== "" ? getTranslation("answerNotListed") : ""
}
function pdpCardIsAdvised(pdpControl: PdpCard | undefined): boolean {
  return (
    !!pdpControl &&
    (pdpControl.payload["pdp-match"] === "GOOD" ||
      pdpControl.payload["pdp-match"] === "BEST")
  )
}
