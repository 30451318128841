<script lang="ts">
  import type { SendInternalAnalytic } from "../../analytics/internal/types"
  import type { CustomerInfoFormConfig } from "../../types"
  import type { CustomerInfoSubmittedAnalytics } from "../../analytics/integrations/IntegrationsAnalytics"
  import CustomerInfoForm from "./CustomerInfoForm.svelte"
  import { createFormState, type FormStateStore } from "./CustomerInfoFormStore"
  import { handleInternalAnalytics } from "./analyticsCallback"
  import { abTestStore } from "../../ABTest"
  import Plane from "../../ui/Plane.svelte"

  export let internalAnalytics: SendInternalAnalytic
  export let formConfig: CustomerInfoFormConfig
  export let customerAnalytics: CustomerInfoSubmittedAnalytics

  const formState: FormStateStore = createFormState(
    (email, checkboxChecked) => {
      handleInternalAnalytics(
        $abTestStore.activeVariant,
        internalAnalytics,
        checkboxChecked
      )

      customerAnalytics.noResultsCustomerInfoSubmitted({
        email,
        checkboxChecked,
        checkboxText: formConfig.checkboxLabel,
      })
    }
  )
</script>

<Plane>
  <CustomerInfoForm {formConfig} {formState} />
</Plane>
