<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  display="block"
  preserveAspectRatio="xMidYMid"
  viewBox="0 0 100 100"
>
  <circle
    cx="50"
    cy="50"
    r="41"
    fill="none"
    stroke="currentColor"
    stroke-dasharray="193.208 66.403"
    stroke-width="10"
  >
    <animateTransform
      attributeName="transform"
      dur="1s"
      keyTimes="0;1"
      repeatCount="indefinite"
      type="rotate"
      values="0 50 50;360 50 50"
    />
  </circle>
</svg>
