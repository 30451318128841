<p class="small-text">
  <slot />
</p>

<style>
  p {
    color: var(--aiden-small-text-color, #000);
    font-weight: var(--aiden-small-text-font-weight, 400);
    font-family: var(--aiden-small-text-font, inherit);
    font-size: clamp(
      12px,
      calc(var(--aiden-regular-text-font-size) * 0.875),
      2rem
    );
    /* var(--aiden-small-text-font-size, 0.75rem); */
    margin-top: 0;
    margin-bottom: 1rem;
  }

  p:last-child {
    margin-bottom: 0;
  }

  p:empty {
    display: none;
  }
  .small-text :global(a),
  .small-text :global(a:visited) {
    color: var(--aiden-link-color, #1236a1);
  }
  .small-text {
    text-wrap: pretty;
  }
</style>
