<svelte:options customElement="aiden-customer-info-form" />

<script lang="ts">
  import AnswerOption from "../ui/AnswerOption.svelte"
  import Image from "../Image.svelte"
  import Input from "../ui/Input.svelte"
  import Plane from "../ui/Plane.svelte"
  import FormLabel from "../ui/FormLabel.svelte"
  import CheckBox from "../ui/CheckBox.svelte"
  import SmallText from "../ui/SmallText.svelte"

  export let checked: string = "false"
</script>

<div>
  <Plane>
    <Input label="Your email" value="email@example.com" />
    <div style="display: flex; gap: 0.5rem; margin-top: 0.25rem;">
      <CheckBox checked={checked === "true"} />
      <SmallText>Sign up for the newsletter</SmallText>
      <!-- <FormLabel label= /> -->
    </div>
  </Plane>
</div>

<style>
  div :global(*) {
    cursor: default !important;
  }
</style>
