<svelte:options customElement="aiden-button" />

<script lang="ts">
  import Button from "../ui/Button.svelte"

  export let variant: string = "primary"
  export let disabled: string = ""
  export let hover: string = "false"
</script>

<div>
  <Button
    previewHover={hover === "true"}
    disabled={disabled === "true"}
    variant={variant === "primary"
      ? "primary"
      : variant === "secondary"
        ? "secondary"
        : variant === "navigation"
          ? "navigation"
          : "primary"}
  >
    <slot></slot>
  </Button>
</div>

<style>
  div :global(*) {
    cursor: default !important;
  }
</style>
