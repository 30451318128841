<svelte:options customElement="aiden-small-text" />

<script lang="ts">
  import Markdown from "../ui/Markdown.svelte"
  import SmallText from "../ui/SmallText.svelte"

  export let markdown: string = ""
</script>

<SmallText>
  {#if markdown}
    <Markdown source={markdown} allowed="extended" />
  {:else}
    <slot />
  {/if}
</SmallText>
