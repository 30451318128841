// {initializing}
// {loadingPrevScreen}
// {reset}
// {hasPrevScreen}
// {prev}

import { writable } from "svelte/store"

export let initializing: boolean = false
export let loadingPrevScreen: boolean = false
export let progress: number
export let hasPrevScreen: boolean
export let hasNextScreen: boolean
export let prev: () => void
export let next: () => void
export let reset: () => void

type NavigationContext = {
  initializing: boolean
  refreshing: boolean
  startingOver: boolean
  loadingPreviousScreen: boolean
  hasPreviousScreen: boolean
  loadingNextScreen: boolean
  hasNextScreen: boolean
  progress: number

  prev: () => void
  next: () => void
  reset: () => void
}

export const navigationContext = writable<NavigationContext>({
  initializing: false,
  refreshing: false,
  startingOver: false,
  loadingPreviousScreen: false,
  hasPreviousScreen: false,
  loadingNextScreen: false,
  hasNextScreen: false,
  progress: 0,

  prev: () => {},
  next: () => {},
  reset: () => {},
})
