<script lang="ts">
  import Page from "../app/Page.svelte"
  import type { Page as PageT } from "../app/toPage"
  import { navigationContext } from "../stores/navigation"

  const page: PageT = {
    type: "question",
    screenId: "question",
    title: "Single answer question title",
    text: "Single answer question intro text. Ipsum Mattis Nibh Euismod Tortor",
    media: undefined,
    questionConfig: {
      type: "single",
      complete: true,
      attributeInstanceId: "string",
      neutralAnswerValue: undefined,
      options: [
        {
          label: "Option 1",
          helpText:
            "Maecenas sed diam eget risus varius blandit sit amet non magna.",
          image: undefined,
          value: "string",
        },
        {
          label: "Option 2",
          helpText:
            "Maecenas sed diam eget risus varius blandit sit amet non magna.",
          image: undefined,
          value: "string",
        },
        {
          label: "Option 3",
          helpText:
            "Maecenas sed diam eget risus varius blandit sit amet non magna.",
          image: undefined,
          value: "string",
        },
      ],
    },
  }
  $: {
    navigationContext.set({
      refreshing: false,
      loadingNextScreen: false,
      startingOver: false,
      loadingPreviousScreen: false,
      hasNextScreen: true,
      hasPreviousScreen: true,
      initializing: false,
      progress: 30,
      prev: () => {},
      next: () => {},
      reset: () => {},
    })
  }
</script>

<Page
  {page}
  hidePrev={false}
  getAttribute={() => null}
  setAttribute={() => {}}
  optionsFor={() => Promise.resolve([])}
  internalAnalytics={() => {}}
  customerAnalytics={{
    productClick: () => {},
    customerInfoSubmitted: () => {},
    infoPageCustomerInfoSubmitted: () => {},
    noResultsCustomerInfoSubmitted: () => {},
  }}
></Page>
