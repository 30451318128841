<script lang="ts">
  import type { FadeParams } from "svelte/transition"
  import { expoIn, expoOut } from "svelte/easing"
  import type { SendInternalAnalytic } from "../../analytics/internal/types"
  import type { AdviceCustomerInfoConfig } from "../../types"
  import type { CustomerInfoSubmittedAnalytics } from "../../analytics/integrations/IntegrationsAnalytics"
  import CustomerInfoForm from "./CustomerInfoForm.svelte"
  import MailIcon from "../../icons/MailIcon.svelte"
  import { abTestStore, ABTestVariantCheck } from "../../ABTest"
  import { createFormState, type FormStateStore } from "./CustomerInfoFormStore"
  import { handleInternalAnalytics } from "./analyticsCallback"
  import type { Recommendation } from "../toPage"
  import Helpers from "../../analytics/integrations/Helpers"
  import Plane from "../../ui/Plane.svelte"
  import Button from "../../ui/Button.svelte"
  import Markdown from "../../ui/Markdown.svelte"

  export let recommendations: Recommendation[]
  export let formConfig: AdviceCustomerInfoConfig
  export let internalAnalytics: SendInternalAnalytic
  export let customerAnalytics: CustomerInfoSubmittedAnalytics
  let title = formConfig.title
  let intro = formConfig.intro
  let showFormButtonLabel = formConfig.ctaButtonLabel

  let advisedProducts = Helpers
  let animationDuration = 400

  function grow(node: any, params: FadeParams) {
    const { delay = 0, duration = 400, easing = expoOut } = params
    const o = +getComputedStyle(node).opacity

    return {
      delay,
      duration,
      css: (t: number) => {
        const val = easing(t)
        return `
          opacity: ${val * o};
        `
      },
    }
  }

  let runAdviceCustomerInfoTest = false
  $: {
    runAdviceCustomerInfoTest = ABTestVariantCheck.isAdviceCustomerInfoVariantB(
      $abTestStore.activeVariant
    )
  }

  const formState: FormStateStore = createFormState(
    (email, checkboxChecked) => {
      handleInternalAnalytics(
        $abTestStore.activeVariant,
        internalAnalytics,
        checkboxChecked
      )

      customerAnalytics.customerInfoSubmitted({
        email,
        checkboxChecked,
        products: Helpers.recommendationsToAdvisedProducts(recommendations),
        checkboxText: formConfig.checkboxLabel,
      })
    }
  )

  let buttonClicked = false
  let showCustomerInfo = false
  let formHeight = 0
  let buttonHeight = 0
  let wrapperHeight = 0
  let wrapperHeightW = 0
  $: wrapperHeight = !showCustomerInfo ? buttonHeight : formHeight
</script>

<div
  class="advice-customer-info"
  style={buttonClicked
    ? `max-height: ${wrapperHeight || wrapperHeightW || 0}px`
    : ""}
  bind:clientHeight={wrapperHeightW}
>
  {#if !showCustomerInfo && !runAdviceCustomerInfoTest}
    <div
      bind:clientHeight={buttonHeight}
      in:grow|local={{
        duration: 0,
        delay: animationDuration,
        easing: expoOut,
      }}
      out:grow|local={{
        duration: 0,
        easing: expoIn,
      }}
    >
      <Button
        onClick={() => {
          buttonClicked = true
          showCustomerInfo = true
        }}
        variant="secondary"
        testId={"open-customer-info-open"}
      >
        <MailIcon />
        {showFormButtonLabel}
      </Button>
    </div>
  {:else}
    <div
      in:grow|local={{
        duration: animationDuration,
        delay: 100,
        easing: expoOut,
      }}
      out:grow|local={{
        duration: animationDuration,
        easing: expoIn,
      }}
      bind:clientHeight={formHeight}
    >
      <div class="form">
        <CustomerInfoForm
          {formState}
          resetAfterSubmit
          onResetAfterSubmit={() => {
            showCustomerInfo = false
          }}
          {formConfig}
        >
          <h2>{title}</h2>
          <div class="form-intro">
            <Markdown allowed="default" source={intro} />
          </div>
        </CustomerInfoForm>
      </div>
    </div>
  {/if}
</div>

<style>
  h2 {
    text-align: center;
    margin: 0 0 0.5rem 0;
    font-size: clamp(
      1rem,
      calc(var(--aiden-advice-page-header-title-font-size, 1rem) * 0.75),
      1.5rem
    );
    color: var(--aiden-advice-page-title-color, #000);
  }
  h2 {
    text-align: left;
  }
  .form-intro {
    white-space: break-word;
    margin-bottom: 1rem;
  }
  .form-intro :global(p) {
    margin-top: 0;
  }

  .advice-customer-info {
    margin-left: auto;
    margin-right: auto;
    padding: 1rem 0;
    margin-bottom: 1rem;
    transition: max-height 300ms linear;
    overflow: hidden;
    display: grid;
    grid-template-rows: 1;
    grid-template-columns: 1;

    --aiden-regular-text-color: var(
      --aiden-advice-page-header-regular-text-color,
      #000
    );
    color: var(--aiden-regular-text-color);
  }

  :global(.show-customer-info-btn) {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    white-space: normal !important;
  }
  :global(.show-customer-info-btn) :global(svg) {
    margin-right: 0.35em !important;
    display: inline-block;
    width: 1.25em !important;
    height: 1.25em !important;
  }
</style>
