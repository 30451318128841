import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Mostre mais",
  showLess: "Mostre menos",
  bestMatch: "Melhor partida",
  view: "Dê uma vista de olhos",
  customAction: "",
  notFound: "404: não encontrado",
  reset: "Recomeçar",
  close: "Fechar",
  noAdviceTitle:
    "Atualmente não há produtos disponíveis que correspondam totalmente às suas preferências.",
  noAdviceMessage: "Ajuste as suas preferências ou tente novamente mais tarde.",
  prev: "Anterior",
  next: "Próximo",
  backToAdvice: "Voltar ao conselho",
  dontKnow: "Pular",
  email: "Endereço de email",
  send: "Enviar",
  emailError: "Digite um endereço de e-mail válido",
  readMore: "Mais informação",
  selectAnAnswer: "Selecione uma resposta",
  answerNotListed: "Minha resposta não está aí",
  whyThisProductForMe: "Por que isso combina comigo?",
  alternative: "alternativa",
  alternatives: "alternativas",
  seeAlso: "Olhe também",
}

export default translations
