import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Mostrar más",
  showLess: "Muestra menos",
  bestMatch: "Mejor partido",
  view: "Mirar",
  customAction: "",
  notFound: "404: no encontrado",
  reset: "Volver a empezar",
  close: "Cerrar",
  noAdviceTitle:
    "Actualmente no hay productos disponibles que coincidan completamente con sus preferencias.",
  noAdviceMessage: "Ajuste sus preferencias o vuelva a intentarlo más tarde.",
  prev: "Previo",
  next: "Proximo",
  backToAdvice: "Volver al consejo",
  dontKnow: "Saltar",
  email: "Dirección de email",
  send: "Enviar",
  emailError: "Ingrese una dirección de correo válida",
  readMore: "Lee mas",
  selectAnAnswer: "Seleccione una respuesta",
  answerNotListed: "Mi respuesta no esta en la lista",
  whyThisProductForMe: "¿Por qué este consejo?",
  alternative: "alternativa",
  alternatives: "alternativas",
  seeAlso: "Mira también",
}

export default translations
