<script lang="ts">
  import type { PredefinedOption } from "../app/toPage"
  import AnswerOption from "./AnswerOption.svelte"
  import Layout from "./Layout.svelte"
  import Bounded from "./Bounded.svelte"
  import Markdown from "./Markdown.svelte"
  import Image from "../Image.svelte"

  export let options: PredefinedOption[]
  export let type: "single" | "multi"
  export let isSelected: (option: PredefinedOption) => boolean = () => false
  export let onSelect: (option: PredefinedOption) => void = () => {}

  let hasImages = false
  $: hasImages = options.some((opt) => opt.image)
</script>

<Bounded unbound={true}>
  <Layout type={"grid"}>
    {#each options as option}
      <AnswerOption
        title={option.label}
        onClick={() => onSelect(option)}
        selected={isSelected(option)}
        {type}
      >
        <span slot="image">
          {#if option.image}
            <Image alt="" src={option.image} />
          {/if}
        </span>
        {#if option.helpText}
          <Markdown source={option.helpText} allowed="default" />
        {/if}
      </AnswerOption>
    {/each}
  </Layout>
</Bounded>
