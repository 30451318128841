<svelte:options customElement="aiden-drilldown-select" />

<script lang="ts">
  import DrilldownSelect from "../ui/DrilldownSelect.svelte"
  import Plane from "../ui/Plane.svelte"

  export let label: string = ""
</script>

<div>
  <Plane>
    <DrilldownSelect options={[]} {label} value={""} />
  </Plane>
</div>

<style>
  div :global(*) {
    cursor: default !important;
  }
</style>
