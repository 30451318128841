<script lang="ts">
  import type { SendInternalAnalytic } from "../analytics/internal/types"
  import { navigationContext } from "../stores/navigation"
  import BottomNavigation from "../ui/BottomNavigation.svelte"
  import type { IntegrationsAnalyticsForComponents } from "../analytics/integrations/IntegrationsAnalytics"
  import {
    type FormStateStore,
    createFormState,
  } from "../customerInfoForm/CustomerInfoFormStore"
  import { abTestStore } from "../ABTest"
  import CustomerInfoForm from "./customerInfo/CustomerInfoForm.svelte"
  import { handleInternalAnalytics } from "../customerInfoForm/analyticsCallback"
  import type { InfoPage, Page } from "./toPage"
  import EmbeddedVideo from "../EmbeddedVideo.svelte"
  import Image from "../Image.svelte"
  import Bounded from "../ui/Bounded.svelte"
  import TopHeader from "../ui/TopHeader.svelte"
  import Plane from "../ui/Plane.svelte"

  export let page: InfoPage
  export let internalAnalytics: SendInternalAnalytic
  export let customerAnalytics: IntegrationsAnalyticsForComponents

  const formIdentifier = page.customerInfoForm.formIdentifier

  const formState: FormStateStore = createFormState(
    (email, checkboxChecked) => {
      handleInternalAnalytics(
        $abTestStore.activeVariant,
        internalAnalytics,
        checkboxChecked
      )

      customerAnalytics.infoPageCustomerInfoSubmitted({
        email,
        checkboxChecked,
        checkboxText: page.customerInfoForm.checkboxLabel,
        formIdentifier,
      })

      $navigationContext.hasNextScreen && $navigationContext.next()
    }
  )
</script>

<Bounded>
  <TopHeader title={page.title} text={page.text}>
    {#if page.media?.type === "image"}
      <Image src={page.media.src} alt="" />
    {:else if page.media?.type === "video"}
      <EmbeddedVideo>
        {@html page.media.src}
      </EmbeddedVideo>
    {/if}
  </TopHeader>
  {#if page.customerInfoForm.enabled}
    <Plane>
      <CustomerInfoForm
        formConfig={page.customerInfoForm}
        withSubmitButton={!$navigationContext.hasNextScreen}
        {formState}
      />
    </Plane>
  {/if}
</Bounded>
<BottomNavigation
  onPrev={$navigationContext.prev}
  hideNext={!$navigationContext.hasNextScreen}
  onNext={() => {
    if ($formState.email) {
      formState.submit() && $navigationContext.next()
    } else {
      $navigationContext.next()
    }
  }}
/>
