<script lang="ts">
  import Button from "../Button.svelte"
  export let href: string | undefined = undefined
  export let target: string | undefined = "_parent"
</script>

{#if href}
  <a class="product-link" {href} {target}>
    <Button isFullWidth variant="primary" className="product-link">
      <slot />
    </Button>
  </a>
{:else}
  <Button isFullWidth variant="primary" className="product-link">
    <slot />
  </Button>
{/if}
