import { derived, get, writable } from "svelte/store"

import type { Translations } from "../types"

import en from "./en"
import nl from "./nl"
import da from "./da"
import de from "./de"
import fr from "./fr"
import it from "./it"
import es from "./es"
import el from "./el"
import fi from "./fi"
import no from "./no"
import sv from "./sv"
import pl from "./pl"
import pt from "./pt"

const translations: Record<string, Translations> = {
  "en-US": en,
  "en-GB": en,
  nl: nl,
  da: da,
  de: de,
  fr: fr,
  it: it,
  es: es,
  el: el,
  fi: fi,
  no: no,
  sv: sv,
  pl: pl,
  pt: pt,
}
const defaultLocale = "en-US"

const locale = writable(defaultLocale)
function i18nStore() {
  const { subscribe, set } = locale

  const changeLocale = (_locale: string) => {
    if (Object.keys(translations).includes(_locale)) {
      set(_locale)
    } else {
      const lang: string = _locale.split("-")[0]
      if (lang === "") return

      const closeMatch: string | undefined = Object.keys(translations).find(
        (t) => t.startsWith(lang)
      )
      if (closeMatch) {
        console.info(
          `${_locale} not supported, changing to close match ${closeMatch}`
        )
        set(closeMatch)
      }
    }
  }

  return {
    subscribe,
    setupI18n: (
      { withLocale: _locale } = {
        withLocale: defaultLocale,
      }
    ) => changeLocale(_locale),
    changeLocale,
  }
}

export const i18n = i18nStore()

export const getTranslation = (
  key: keyof Translations,
  interpolate: Record<string, string> = {}
) => {
  const trans: string = translations[get(i18n)][key] || ""
  if (!trans) {
    console.error(`translation missing for key: ${key}`)
  }
  return Object.entries(interpolate).reduce(
    (translation, [key, value]) => translation.replace(`{{${key}}}`, value),
    trans
  )
}

export const t = derived([i18n], () => getTranslation)
export const selectedLocale = locale
