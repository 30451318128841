<script lang="ts">
  export let label: string
</script>

<label for="">{label}</label>

<style>
  label {
    font-weight: bold;
    margin-bottom: 0.25rem;
    display: inline-block;
    font-family: var(--aiden-input-label-font-family, "system-ui");
    font-weight: var(--aiden-input-label-font-weight, 600);
    color: var(--aiden-input-label-color, #000);
  }
</style>
