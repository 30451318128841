import ReactDOM from "react-dom"
import "modern-normalize/modern-normalize.css"
import App from "./App"
import { define as defineImgWithFallback } from "~/webcomponents/img-with-fallback"
import { define as defineQuillEditor } from "~/webcomponents/quill-editor"
import { define as definePopper } from "~/webcomponents/popper"
import { define as defineModalDialog } from "~/webcomponents/modal-dialog"
import { define as defineTutorialLink } from "~/webcomponents/tutorial-link"
import { define as defineAidenPreview } from "~/webcomponents/aiden-preview"
import { define as defineFormattedCurrency } from "~/webcomponents/currency-format"
import { define as defineInView } from "~/webcomponents/in-view"
import { define as defineAidenPreviewV2 } from "~/webcomponents/aiden-preview-v2"
import "~/webcomponents/color-picker"
import "@github/clipboard-copy-element"

defineImgWithFallback()
defineQuillEditor()
definePopper()
defineModalDialog()
defineTutorialLink()
defineAidenPreview()
defineFormattedCurrency()
defineInView()
defineAidenPreviewV2()

const render = () => <App />

ReactDOM.render(render(), document.getElementById("root"))
