<script lang="ts">
  import ProductFeatures from "../controls/ProductFeatures.svelte"
  import ArrowRight from "../icons/ArrowRight.svelte"
  import { t } from "../lang/i18n"
  import ActionButton from "../ui/ProductCard/ActionButton.svelte"

  import ProductCard from "../ui/ProductCard/ProductCard.svelte"
  import ProductCardFeatures from "../ui/ProductCard/ProductCardFeatures.svelte"
  import ProductCardFields from "../ui/ProductCard/ProductCardFields.svelte"
  import ProductCardImage from "../ui/ProductCard/ProductCardImage.svelte"
  import ProductCardName from "../ui/ProductCard/ProductCardInfo/ProductCardName.svelte"
  import ProductCardPrice from "../ui/ProductCard/ProductCardInfo/ProductCardPrice.svelte"
  import ProductCardVariants from "../ui/ProductCard/ProductCardVariants.svelte"
  import ViewProductButton from "../ui/ProductCard/ViewProductButton.svelte"
  import type { Recommendation } from "./toPage"

  export let recommendation: Recommendation
  export let disabled: boolean = false
</script>

<ProductCard {disabled}>
  <span slot="product-card-tags" class="product-card-tags"><slot></slot></span>
  <span slot="product-card-image">
    <ProductCardImage
      productName={recommendation.productName}
      imageUrl={recommendation.imageUrl}
      productUrl={recommendation.productUrl}
      linkTarget="_parent"
    />
  </span>
  <span slot="product-card-body">
    <ProductCardName
      productUrl={recommendation.productUrl}
      productName={recommendation.productName}
      linkTarget="_parent"
    />
    {#if recommendation.price && recommendation.display.price}
      <ProductCardPrice
        prefix={recommendation.pricePrefix}
        suffix={recommendation.priceSuffix}
        price={recommendation.price}
        salePrice={recommendation.salePrice?.price}
      />
    {/if}
    {#if recommendation.additionalFields.length}
      <ProductCardFields productFields={recommendation.additionalFields} />
    {/if}
  </span>
  <span slot="product-card-buttons">
    {#if !disabled}
      {#if recommendation.display.productCta}
        {#if recommendation.display.ctaType === "AddToCart"}
          <ActionButton
            clientProductId={recommendation.clientProductId}
            productName={recommendation.productName}
            actionIdentifier={"aiden-add-to-cart"}
          >
            {recommendation.buttonCtaText || $t("customAction")}
          </ActionButton>
        {/if}
        {#if recommendation.display.ctaType === "ProductLink" && recommendation.productUrl}
          <ViewProductButton href={recommendation.productUrl}>
            {recommendation.buttonCtaText || $t("view")}
            <ArrowRight />
          </ViewProductButton>
        {/if}
      {/if}
    {/if}
  </span>
  <div slot="product-card-bottom">
    {#if recommendation.display.traceability}
      <ProductCardFeatures horizontal features={recommendation.traceability} />
    {/if}
    {#if recommendation.variants}
      <ProductCardVariants variants={recommendation.variants} />
    {/if}
  </div>
</ProductCard>
