<script lang="ts">
  import BadMatch from "../../icons/BadMatch.svelte"
  import Good from "../../icons/Good.svelte"
  import Perfect from "../../icons/Perfect.svelte"
  import type { Traceability } from "../../types"

  export let features: Traceability[] = []
  export let responsive: boolean = false
  export let horizontal: boolean = true

  export let limit = 3
</script>

<div class="product-features">
  <ul
    class="product-features-list"
    class:horizontal
    class:responsive
  >
    {#each features as feature, i}
      {#if i + 1 <= limit}
        <li class:responsive class="product-feature">
          <span
            class="icon-container"
            class:bad-match={feature.score === "bad"}
            class:good-match={feature.score === "good" ||
              feature.score === "perfect"}
          >
            {#if feature.score == "bad"}
              <BadMatch />
            {:else if feature.score == "good"}
              <Good />
            {:else if feature.score == "perfect"}
              <Perfect />
            {/if}
          </span>
          <span class="answer">{feature.answer}</span>
        </li>
      {/if}
    {/each}
  </ul>
</div>

<style>
  .product-features {
    grid-column: 1/-1;
  }

  .product-features-list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0.75rem;
    display: grid;
    gap: 0.125rem 0;

    &.horizontal {
      display: inline-flex;
      flex-wrap: wrap;
      gap: 0.125rem 0.5rem;
    }
  }

  .product-feature {
    display: flex;
    align-items: start;

    & .answer {
      padding-left: 2px;
      padding-top: 0.1875rem;
      word-break: break-word;
      min-height: 1.5rem;
    }

    & .icon-container {
      height: 1.5rem;
      display: inline-block;
      vertical-align: middle;

      &.bad-match {
        color: #C3C3C3;
        width: 1.5rem;
        display: flex;
        justify-content: center;
      }

      &.good-match {
        color: #00AD00;
      }
    }

    &.responsive {
      display: flex;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (min-width: 721px) {
    .product-features {
      margin-top: 0;
    }
  }
</style>
