import type {
  ActionTriggered,
  CustomerInfoFormSubmitted,
  CustomerInfoFormWithCheckboxSubmitted,
  InternalAnalyticsEvent,
  ProductLinkClicked,
  ProductLinkRightClicked,
  ProductRecommendationContext,
  TriggerLocation,
} from "../../analytics/internal/types"

type Partial<E extends InternalAnalyticsEvent> = Omit<
  E,
  "screenId" | "sessionId" | "clientInfo"
>

export default {
  productLinkEvent: (
    productId: string,
    type: "LINK" | "LINK_RIGHT_CLICK",
    context: ProductRecommendationContext,
    recommendationPosition: number | null,
    testVariant: string | null,
    triggerLocation: TriggerLocation
  ): Partial<ProductLinkClicked> | Partial<ProductLinkRightClicked> => ({
    type: type,
    identifier: productId,
    testVariant: testVariant,
    context,
    recommendationPosition,
    triggerLocation
  }),

  customerInformationSubmittedEvent: (
    testVariant: string | null
  ): Partial<CustomerInfoFormSubmitted> => ({
    type: "CUSTOMER_INFO_SUBMITTED",
    testVariant: testVariant,
  }),

  customerInformationSubmittedWithCheckboxEvent: (
    testVariant: string | null
  ): Partial<CustomerInfoFormWithCheckboxSubmitted> => ({
    type: "CUSTOMER_INFO_SUBMITTED_WITH_CHECKBOX",
    testVariant: testVariant,
  }),

  customActionEvent: (
    actionIdentifier: string,
    recommendationContext: ActionTriggered["recommendationContext"],
    location: ActionTriggered["location"],
    triggerLocation: ActionTriggered["triggerLocation"],
    products: ActionTriggered["products"],
    testVariant: string | null
  ): Partial<ActionTriggered> => ({
    type: "ACTION",
    testVariant: testVariant,
    actionIdentifier: actionIdentifier,
    location: location,
    triggerLocation: triggerLocation,
    recommendationContext,
    products,
  }),
}
