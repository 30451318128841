<script lang="ts">
  import Markdown from "../ui/Markdown.svelte"
  import AnswerOption from "../ui/AnswerOption.svelte"
  import Layout from "../ui/Layout.svelte"
  import BottomNavigation from "../ui/BottomNavigation.svelte"
  import Bounded from "../ui/Bounded.svelte"
  import TopHeader from "../ui/TopHeader.svelte"
  import Plane from "../ui/Plane.svelte"
  import NumberInput from "../ui/NumberInput.svelte"
</script>

<div class="aiden-screen-content">
  <Bounded>
    <TopHeader
      title="Numeric question title"
      text="Numeric question intro text. Ipsum Mattis Nibh Euismod Tortor"
    />
  </Bounded>
  <Bounded>
    <Plane>
      <NumberInput
        onChange={() => {}}
        label=""
        value={null}
        allowDecimals
        info={{ position: "suffix", label: "cm" }}
      />
    </Plane>
  </Bounded>
  <slot></slot>
</div>
