export const debounce = <T>(func: (...args: T[]) => void, wait = 500) => {
  let timeout: number | undefined = undefined

  return function executedFunction(...args: T[]) {
    const later = () => {
      clearTimeout(timeout)
      func(...args)
    }

    clearTimeout(timeout)
    timeout = window.setTimeout(later, wait)
  }
}

export default {
  debounce,
}
