<script lang="ts">
  import ImageWithFallback from "./ImageWithFallback.svelte"

  export let productUrl: string | undefined
  export let linkTarget: "_parent" | "_blank"
  export let imageUrl: string
  export let productName: string
</script>

<div class="product-card-image">
  {#if productUrl}
    <a data-testid="product-image-link" href={productUrl} target={linkTarget}>
      <ImageWithFallback src={imageUrl} alt={productName} />
    </a>
  {:else}
    <ImageWithFallback src={imageUrl} alt={productName} />
  {/if}
</div>

<style>
  .product-card-image {
    display: flex;
    place-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
    height: 14rem;
    font-weight: bold;
  }
  .product-card-image :global(img),
  .product-card-image a,
  .product-card-image a:visited {
    width: 100%;
    max-height: 100%;
    display: flex;
    place-content: center;
  }
  .product-card-image a {
    height: 100%;
  }
  .product-card-image,
  .product-card-image a,
  .product-card-image a:visited {
    color: #000 !important;
  }

  @container card  (min-width: 500px) {
    .product-card-image :global(img) {
      /* width: 12rem; */
      height: 100%;
      max-height: 14rem;
      margin-bottom: 0;
    }
  }
</style>
