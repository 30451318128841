<script lang="ts">
  let failed = false

  export let src: string = ""
  export let alt: string | undefined
</script>

{#if !src || failed}
  <div
    class="product-card-image failure"
    title={alt || ""}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 30 32"
      xml:space="preserve"
      fill="currentColor"
    >
      <g transform="translate(-570 -80)">
        <g
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <circle cx="579" cy="92" r="2" />
          <polygon
            points="590.908,86 590.315,88 595,88 595,103 585.871,103
          585.278,105 597,105 597,86 "
          />
          <path
            d="M586.167,102H588h6v-2.857c-1.997-2.776-2.954-6.657-4.883-7.098L586.167,102z"
          />
          <path
            d="M588.041,81.716L586.771,86H573v19h8.143l-1.102,3.716l1.918,0.568l8-27L588.041,81.716z
          M583.31,97.682
          c-0.668-0.861-1.34-1.396-2.06-1.396c-1.955,0-2.674,4.157-5.25,4.999V102h2.25h3.781l-0.296,1H575V88h11.18L583.31,97.682z"
          />
        </g>
      </g>
    </svg>
  </div>
{:else}
  <img
    {src}
    class="product-card-image"
    title={alt || ""}
    alt={alt || ""}
    loading="lazy"
    on:error={() => (failed = true)}
  />
{/if}

<style>
  .failure {
    aspect-ratio: 400/300;
    background-color: #f4f4f4;
    width: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
  }

  svg {
    opacity: 0.1;
    margin: 0 auto;
    max-height: 8rem;
    display: block;
    object-fit: scale-down;
    width: 100%;
    max-width: 4rem;
  }
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: scale-down;
    width: 100%;
    height: 100%;
  }
</style>
