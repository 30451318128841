<script lang="ts">
  export let label: string
  export let value: string
</script>

<div>
  {#if label}
    <label for="input">{label}</label>
  {/if}

  <input name="input" type="text" autocomplete="off" {value} />
</div>

<style>
  input {
    width: 100%;
    font-size: 1em;
    border: 0px solid transparent;
    margin: 0 auto;
    box-sizing: border-box;
    -moz-appearance: textfield;
    appearance: textfield;
    background: transparent;
    font-family: var(--aiden-regular-text-font, inherit);
    background: var(--aiden-input-background-color, #fff);
    border-radius: var(--aiden-input-border-radius, 0.5rem);
    border: var(--aiden-input-border-width, 1px) solid
      var(--aiden-input-border-color, #ebebeb);
    color: var(--aiden-input-font-color, #000);
    height: var(--aiden-input-height, 3.5rem);
    padding: 1rem;
    outline: none;
  }
  input:focus-visible,
  input:focus {
    outline: none;
    border-color: var(--aiden-input-border-color-focus, #000);
  }

  label {
    font-weight: bold;
    margin-bottom: 0.25rem;
    display: inline-block;
    font-family: var(--aiden-input-label-font-family, "system-ui");
    font-weight: var(--aiden-input-label-font-weight, 600);
    color: var(--aiden-input-label-color, #000);
  }
</style>
