<script lang="ts">
  import Cross from "../icons/Cross.svelte"
  import Loader from "../icons/Loader.svelte"
  import ResetNew from "../icons/ResetNew.svelte"
  import { t } from "../lang/i18n"
  import { navigationContext } from "../stores/navigation"
  import Bounded from "./Bounded.svelte"
  import Button from "./Button.svelte"
  import ProgressBar from "./ProgressBar.svelte"

  export let progress: number = 0
  export let hideProgress = false
  export let hideStartOver = false
  export let hideClose = false
  export let onStartOver: () => void = () => {}
</script>

<!-- <Bounded> -->
<div
  class="top-navigation max-app-width"
  class:is-hidden={hideStartOver && hideClose && hideProgress}
>
  <div class="buttons">
    {#if !hideStartOver}
      <Button variant="navigation" onClick={onStartOver}>
        <span> {$t("reset")}</span>
        {#if $navigationContext.startingOver}
          <Loader />
        {:else}
          <ResetNew />
        {/if}
      </Button>
    {:else}
      <div />
    {/if}

    {#if !hideClose}
      <Button variant="navigation">
        <span> {$t("close")}</span>
        <Cross />
      </Button>
    {/if}
  </div>
  <div class="progress-bar" class:is-hidden={hideProgress}>
    <ProgressBar {progress} />
  </div>
</div>

<!-- </Bounded> -->

<style>
  .top-navigation {
    padding: 0.25rem 0 0 0;
    padding-left: var(--frame-padding, 1rem);
    padding-right: var(--frame-padding, 1rem);
    padding-top: var(--frame-padding, 1rem);
    background-color: var(--aiden-top-navigation-background-color);

    /* min-height: 3.5rem; */
    width: 100%;
    box-sizing: border-box;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    align-items: center;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .progress-bar {
    /* transition: 150ms all ease-out; */
    margin-top: 0.25rem;
    padding: 0;
    transition-behavior: allow-discrete;
  }
  .is-hidden {
    display: none;
    opacity: 0;
  }
</style>
