<script lang="ts">
  export let productUrl: string | undefined
  export let productName: string
  export let linkTarget: "_parent" | "_blank"
</script>

{#if productUrl}
  <a
    class="product-name"
    href={productUrl}
    target={linkTarget}
  >
    {productName}
  </a>
{:else}
  <div class="product-name">{productName}</div>
{/if}

<style>
  .product-name {
    color: var(--aiden-product-name-color, #1D264D);
    font-weight: var(--aiden-product-name-font-weight, bold);
    font-size: var(--aiden-product-name-font-size, 1rem);
    font-family: var(--aiden-product-name-font-family, inherit);
    line-height: 1.25;
    text-decoration: none;
  }

  a.product-name:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }

  @container card  (min-width: 500px) {
    .product-name {
      font-size: 1.125rem;
    }
  }
  @container card  (min-width: 640px) {
    .product-name {
      font-size: 1.25rem;
    }
  }
</style>
