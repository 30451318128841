import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Mostra altro",
  showLess: "Mostra meno",
  bestMatch: "Miglior match",
  view: "Guarda",
  customAction: "",
  notFound: "404: non trovato",
  reset: "Ricominciare",
  close: "Chiudere",
  noAdviceTitle:
    "Al momento non ci sono prodotti disponibili che corrispondono pienamente alle tue preferenze.",
  noAdviceMessage: "Regola le tue preferenze o riprova più tardi.",
  prev: "Precedente",
  next: "Prossimo",
  backToAdvice: "Torna ai consigli",
  dontKnow: "Saltare",
  email: "Indirizzo e-mail",
  send: "Inviare",
  emailError: "Inserisci un indirizzo email valido",
  readMore: "Leggi di più",
  selectAnAnswer: "Seleziona una risposta",
  answerNotListed: "La mia risposta non è elencata",
  whyThisProductForMe: "Perché è questo per me?",
  alternative: "alternativa",
  alternatives: "alternative",
  seeAlso: "Guarda anche",
}

export default translations
