<svelte:options customElement="aiden-progress-bar" />

<script lang="ts">
  import ProgressBar from "../ui/ProgressBar.svelte"

  export let progress: string = ""

  let progress_ = 20
  $: {
    try {
      const p = parseInt(progress)
      if (isNaN(p) || !isFinite(p)) {
        progress_ = p
      } else {
        progress_ = p
      }
    } catch (_) {
      progress_ = 20
    }
  }
</script>

<ProgressBar progress={progress_}></ProgressBar>
