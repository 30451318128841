<div>
  <slot></slot>
</div>

<style>
  div {
    background-color: var(--aiden-plane-color, #f4f4f4);
    border-radius: clamp(0rem, var(--aiden-plane-border-radius, 1rem), 1rem);
    padding: 1rem;
    max-width: 40rem;
  }
</style>
