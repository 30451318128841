import Elm from "./ElmWrapper"
import { ElmApp as ElmModule } from "./ElmApp"
import { useEffect, useMemo, useState } from "react"
import ColorContrastChecker from "color-contrast-checker"
import minBy from "lodash/minBy"

const LookAndFeelForm: React.FC = () => {
  const [ports, setupPorts] = useState<any | undefined>(undefined)

  const checker = useMemo(() => {
    return new ColorContrastChecker()
  }, [])

  useEffect(() => {
    if (!ports) return

    const contrastHandler = (contrastChecks) => {
      try {
        const report = checker.checkPairs(contrastChecks).map((c, i) => {
          return { ...c, identifier: contrastChecks[i].identifier }
        })
        ports.receivedContrastReport.send(report)
      } catch (e) {
        console.error(e)
      }
    }

    const closestSectionScroll = () => {
      window.requestAnimationFrame(() => {
        const elems = document.querySelectorAll("[data-scroll-section]")
        const offsets = Array.from(elems)
          .map((elem) => {
            const rect = elem.getBoundingClientRect()
            return { elem, top: rect.y }
          })
          .filter(({ top }) => top < 0)
          .map((e) => ({ ...e, top: e.top * -1 }))

        const closestElem = minBy(offsets, ({ top }) => top)

        ports?.sectionScroll.send(closestElem?.elem?.id || "")
      })
    }

    window.addEventListener("scroll", closestSectionScroll)
    window.addEventListener("resize", closestSectionScroll)

    ports.checkContrast.subscribe(contrastHandler)
    return () => {
      ports.checkContrast.unsubscribe(contrastHandler)
      window.removeEventListener("scroll", closestSectionScroll)
      window.removeEventListener("resize", closestSectionScroll)
    }
  }, [ports, checker])

  return (
    // @ts-ignore
    <Elm
      // @ts-ignore
      src={ElmModule.LookAndFeel}
      flags={{}}
      ports={setupPorts}
    />
  )
}

export default LookAndFeelForm
