import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Pokaż więcej",
  showLess: "Pokaż mniej",
  bestMatch: "Najlepszy mecz",
  view: "Patrzeć na",
  customAction: "",
  notFound: "404: nie znaleziono",
  reset: "Zacząć od nowa",
  close: "Zamknąć",
  noAdviceTitle:
    "Obecnie nie ma dostępnych produktów, które w pełni odpowiadają Twoim preferencjom.",
  noAdviceMessage: "Dostosuj swoje preferencje lub spróbuj ponownie później.",
  prev: "Poprzedni",
  next: "Następna",
  backToAdvice: "Wracając do porad",
  dontKnow: "Pominąć",
  email: "Adres e-mail",
  send: "Wysłać",
  emailError: "Wprowadź prawidłowy adres e-mail",
  readMore: "Czytaj więcej",
  selectAnAnswer: "Wybierz odpowiedź",
  answerNotListed: "Nie ma tam mojej odpowiedzi",
  whyThisProductForMe: "Dlaczego mi to odpowiada?",
  alternative: "alternatywny",
  alternatives: "alternatywy",
  seeAlso: "Także popatrz",
}

export default translations
