import { EnvVar, envVar } from "~/utils/environment"
const webshopPreviewURL = envVar(EnvVar.WEBSHOP_FRONTEND_URL) as string

class AidenPreviewV2 extends HTMLElement {
  constructor() {
    super()
  }

  iframeElem: undefined | HTMLIFrameElement = undefined

  static observedAttributes = [
    "powered-by-aiden",
    "screen-config",
    "style-config",
  ]

  handler = (msg) => {
    if (
      msg.data &&
      msg.data.advisorId === "preview" &&
      msg.data.type === "viewport-dimensions" &&
      this.iframeElem
    ) {
      this.iframeElem.style.height = msg.data.data.height + 1 + "px"
    }
  }

  attributeChangedCallback(_name, _oldValue, newValue) {
    this.syncAttributes()
  }

  syncAttributes() {
    const screenConfig = JSON.parse(
      decodeURIComponent(this.getAttribute("screen-config") || "null")
    )

    const style = JSON.parse(
      decodeURIComponent(this.getAttribute("style-config") || "[]")
    )

    this.iframeElem?.contentWindow?.postMessage(
      {
        messageType: "sync",
        screenConfig,
        style
      },
      "*"
    )
  }

  connectedCallback() {
    const iframe = document.createElement("iframe")
    // @ts-ignore
    iframe.src = import.meta.env.PROD
      ? webshopPreviewURL + "/build/preview-v2.html"
      : webshopPreviewURL + "/preview-v2.html"
    iframe.id = "preview"
    iframe.style.width = "100%"
    iframe.style.border = "none"
    this.iframeElem = iframe
    iframe.onload = () => {
      this.dispatchEvent(new CustomEvent("preview-loaded"))
      this.syncAttributes()
    }

    this.innerHTML = ""
    this.appendChild(iframe)
    window.addEventListener("message", this.handler)
  }

  disconnectedCallback() {
    window.removeEventListener("message", this.handler)
  }
}

const define = () => customElements.define("aiden-preview-v2", AidenPreviewV2)

export { define }
