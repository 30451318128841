<script lang="ts">
  import CheckBox from "./CheckBox.svelte"
  import Chevron from "./Chevron.svelte"
  import RegularText from "./RegularText.svelte"
  import SmallText from "./SmallText.svelte"
  export let title: string | undefined
  export let selected: boolean = false
  // export let text: string = ""
  export let type: "single" | "multi"
  export let onClick: () => void = () => {}
</script>

<button class="answer-option" on:click={onClick} class:is-selected={selected}>
  {#if $$slots.image}
    <div class="image">
      <slot name="image"></slot>
    </div>
  {/if}
  <div class="content">
    <div>
      <strong class="answer-option--title">
        {title}
      </strong>
      {#if $$slots.default}
        <div class="answer-option--text">
          <slot></slot>
        </div>
      {/if}
    </div>
    <div class="indicator">
      {#if type === "single"}
        <Chevron />
      {/if}
      {#if type === "multi"}
        <CheckBox checked={selected} />
      {/if}
    </div>
  </div>
</button>

<style>
  .answer-option {
    border: clamp(1px, var(--aiden-answer-option-border-width, 1px), 4px) solid
      var(--aiden-answer-option-border-color, #dedede);
    background-color: var(--aiden-answer-option-background-color);
    border-radius: var(--aiden-answer-option-border-radius, 1rem);
    color: var(--aiden-answer-option-text-color, #000);
    overflow: hidden;
    width: 100%;
    font-family: inherit;
    text-align: left;
    font-size: inherit;
    cursor: pointer;
    padding: 0;
    transition: 150ms all ease-out;
  }
  .answer-option:has(.image span :global(img)) {
    /* TODO: This doesnt work because span always has content */
    max-width: 560px;
  }
  .content {
    display: grid;
    grid-template-columns: 1fr 1.5rem;
    height: auto;
    padding: 1rem;
    gap: 0.5rem;
    font-family: inherit;
    font-size: inherit;
    align-items: start;
    text-align: left;
    cursor: pointer;
  }
  .answer-option {
    /* var(--aiden-answer-option-indicator-color, #000); */
    --aiden-checkbox-border-color: var(
      --aiden-answer-option-checkbox-border-color,
      #000
    );
    --aiden-checkbox-background-color: var(
      --aiden-answer-option-checkbox-background-color,
      #ebebeb
    );

    --aiden-checkbox-check-color: transparent;

    --aiden-chevron-color: var(--aiden-answer-option-chevron-color, #000);
  }

  @media (hover: hover) {
    .answer-option:hover {
      --aiden-checkbox-border-color: var(
        --aiden-answer-option-checkbox-border-color-selected,
        #000
      );
      --aiden-checkbox-border-color-checked: var(
        --aiden-answer-option-checkbox-border-color-selected,
        #000
      );
      --aiden-checkbox-background-color-checked: var(
        --aiden-answer-option-checkbox-background-color-selected,
        #000
      );

      --aiden-checkbox-check-color-checked: var(
        --aiden-answer-option-checkbox-color-selected,
        #fff
      );

      --aiden-chevron-color: var(
        --aiden-answer-option-chevron-color-selected,
        #000
      );

      border-color: var(--aiden-answer-option-border-color-selected, #000);
    }
  }
  .answer-option:active,
  .answer-option:focus-visible,
  .answer-option.is-selected {
    --aiden-checkbox-border-color: var(
      --aiden-answer-option-checkbox-border-color-selected,
      #000
    );
    --aiden-checkbox-border-color-checked: var(
      --aiden-answer-option-checkbox-border-color-selected,
      #000
    );
    --aiden-checkbox-background-color-checked: var(
      --aiden-answer-option-checkbox-background-color-selected,
      #000
    );

    --aiden-checkbox-check-color-checked: var(
      --aiden-answer-option-checkbox-color-selected,
      #fff
    );

    --aiden-chevron-color: var(
      --aiden-answer-option-chevron-color-selected,
      #000
    );

    border-color: var(--aiden-answer-option-border-color-selected, #000);
  }

  .answer-option--title {
    display: flex;
    align-items: center;
    min-height: 24px;
    color: var(--aiden-answer-option-text-color, #000);
    font-family: var(--aiden-answer-option-font-family, inherit);
    /* --aiden-regular-text-font-weight: 600; */
    font-weight: bold;
  }
  .answer-option--title,
  .answer-option--text {
    font-family: var(--aiden-answer-option-text-font-family, inherit);
    margin: 0;
  }

  .answer-option--text {
    margin-top: 0.25rem;
  }

  .answer-option--text:empty {
    display: none;
  }

  .answer-option .answer-option--text {
    font-size: clamp(
      12px,
      var(--aiden-answer-option-text-font-size, 14px),
      14px
    );
  }
</style>
