<script lang="ts">
  export let topLabel: { kind: "good" | "neutral"; label: string } | undefined
</script>

{#if topLabel}
  <div
    class="tag"
    class:is-good={topLabel?.kind === "good"}
    class:is-neutral={topLabel?.kind === "neutral"}
  >
    {topLabel?.label}
  </div>
{/if}

<style>
  .tag {
    padding: 0.25rem 0.5rem;
    line-height: 1;
    text-wrap: nowrap;
    display: inline-block;
    font-size: 0.75rem;
    text-align: center;
    font-family: var(--aiden-match-label-font-family, inherit);

    &.is-good {
      background-color: var(--aiden-best-match-label-background-color, #07eeb2);
      color: var(--aiden-best-match-label-text-color, #000);
      border-radius: var(--aiden-best-match-label-border-radius, 0.25rem);
      font-weight: var(--aiden-best-match-label-font-weight, normal);
      border-color: var(--aiden-best-match-label-border, transparent);
    }

    &.is-bad {
      background-color: var(--aiden-bad-match-label-background-color, #dedede);
      color: var(--aiden-bad-match-label-text-color, #5f5f5f);
      border-radius: var(--aiden-bad-match-label-border-radius, 0.25rem);
      font-weight: var(--aiden-bad-match-label-font-weight, normal);
      border-color: var(--aiden-bad-match-label-border, transparent);
    }
    &.is-neutral {
      background-color: var(--aiden-bad-match-label-background-color, #dedede);
      color: var(--aiden-bad-match-label-text-color, #5f5f5f);
      border-radius: var(--aiden-bad-match-label-border-radius, 0.25rem);
      font-weight: var(--aiden-bad-match-label-font-weight, normal);
      border-color: var(--aiden-bad-match-label-border, transparent);
    }
  }
</style>
