<svg
  height="12"
  viewBox="0 0 19 12"
  width="19"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="m.87 6.483 5.604 4.795c.09.078.2.117.328.117a.487.487 0 0 0
    .328-.117.358.358 0 0 0 0-.562l-4.802-4.116h15.198a.5.5 0 0 0
    .337-.117.366.366 0 0 0 0-.57.5.5 0 0 0
    -.337-.118h-15.198l4.802-4.115a.358.358 0 0 0 0-.562.372.372 0 0 0
    -.15-.09.587.587 0 0 0 -.356 0 .372.372 0 0 0 -.15.09l-5.604 4.795a.362.362
    0 0 0 -.137.285c0 .112.046.207.137.285z"
    fill="none"
    stroke="currentColor"
  />
</svg>
