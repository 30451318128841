import { writable, type Writable } from "svelte/store"
import type { Integration } from "./types"

export type AdvisorInfo = {
  advisorId: string
  advisorName: string
  integrations: Integration[]
}

function advisorInfo() {
  const store: Writable<AdvisorInfo> = writable({
    advisorName: "",
    advisorId: "",
    integrations: [],
  })
  const { subscribe, update } = store

  return {
    subscribe,
    setName: (newName: string) =>
      update((info) => {
        info.advisorName = newName
        return info
      }),
    setInfo: (advisorInfo: AdvisorInfo) =>
      update((info) => {
        info.advisorName = advisorInfo.advisorName
        info.advisorId = advisorInfo.advisorId
        info.integrations = advisorInfo.integrations
        return info
      }),
    setIntegrations: (newIntegrations: Integration[]) =>
      update((info) => {
        info.integrations = newIntegrations
        return info
      }),
  }
}

export const advisorInfoStore = advisorInfo()
