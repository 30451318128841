<script lang="ts">
  export let type: "grid" | "list" = "list"
</script>

<div class="container">
  <div class="layout is-grid">
    <slot></slot>
  </div>
</div>

<style>
  .container {
    container-type: inline-size;
  }
  .layout {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 0.5rem;
    align-items: start;
  }
  .is-grid {
    display: grid;
    /* flex-wrap: wrap; */
    gap: 1rem;
    grid-template-columns: minmax(0, 1fr);
    align-items: stretch;
  }
  .is-grid > :global(*) {
    /* max-width: 400px !important; */
    display: grid;
    align-content: start;
  }

  @container (min-width: 560px) {
    .is-grid {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    }
  }
  @container (min-width: 60rem) {
    .is-grid {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    }
  }
  @container (min-width: 80rem) {
    .is-grid {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
          0,
          1fr
        );
    }
  }
</style>
