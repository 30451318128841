<p class="regular-text">
  <slot />
</p>

<style>
  p {
    color: var(--aiden-regular-text-color, #000);
    font-weight: var(--aiden-regular-text-font-weight, 400);
    font-family: var(--aiden-regular-text-font, inherit);
    font-size: clamp(1rem, var(--aiden-regular-text-font-size, 1rem), 2rem);
    margin-top: 0;
    margin-bottom: 1rem;
  }
  p:last-child {
    margin-bottom: 0;
  }

  p:empty {
    display: none;
  }
  .regular-text :global(a),
  .regular-text :global(a:visited) {
    color: var(--aiden-link-color, #1236a1);
  }
  .regular-text {
    text-wrap: pretty;
  }
</style>
