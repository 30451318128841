import InternalAnalytics from "../../analytics/internal/InternalAnalytics"
import type { SendInternalAnalytic } from "../../analytics/internal/types"

export function handleInternalAnalytics(
  testVariant: string | null,
  sendInternalAnalytics: SendInternalAnalytic,
  checkboxChecked: boolean
) {
  const analyticFn = checkboxChecked
    ? InternalAnalytics.customerInformationSubmittedWithCheckboxEvent
    : InternalAnalytics.customerInformationSubmittedEvent

  sendInternalAnalytics(analyticFn(testVariant), "CUSTOMER_INFORMATION_FORM")
}
