<script lang="ts">
  import BadMatch from "../icons/BadMatch.svelte"
  import Good from "../icons/Good.svelte"
  import Perfect from "../icons/Perfect.svelte"
  import type { Traceability } from "../types"

  export let features: Traceability[] = []
  export let responsive: boolean = false
  export let horizontal: boolean = false

  export let limit = 3
  let showMore = false

  const toggleMore = (show: boolean) => {
    showMore = show
  }
</script>

<ul
  class="product-features"
  class:horizontal
  class:columns={false}
  class:responsive
>
  {#each features as feature, i}
    {#if i + 1 <= limit || showMore}
      <li class:responsive class="product-feature">
        <span
          class="icon-container"
          class:bad-match={feature.score === "bad"}
          class:success={feature.score === "good" ||
            feature.score === "perfect"}
        >
          {#if feature.score == "bad"}
            <BadMatch />
          {:else if feature.score == "good"}
            <Good />
          {:else if feature.score == "perfect"}
            <Perfect />
          {/if}
        </span>
        <span class="answer">{feature.answer}</span>
      </li>
    {/if}
  {/each}
</ul>

<style>
  .product-features {
    padding: 0;
    list-style: none;
    font-size: 0.75em;
    display: grid;
    gap: 0.125rem 0;
  }

  .product-features.horizontal {
    display: inline-flex;
    flex-wrap: wrap;
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    gap: 0.125rem 0.5rem;
  }

  .product-feature {
    display: flex;
    align-items: start;
  }
  .product-feature .answer {
    padding-left: 2px;
    padding-top: 0.1875rem;
    word-break: break-word;
    /* hyphens: auto; */
    min-height: 1.5em;
  }
  .product-feature .icon-container {
    height: 24px;
    display: inline-block;
    vertical-align: middle;
  }

  .product-feature .icon-container.bad-match {
    color: var(--aiden-helptext-color);
    width: 24px;
    display: flex;
    justify-content: center;
  }

  .product-feature .icon-container.success {
    color: var(--aiden-success);
  }

  .product-feature.responsive {
    display: flex;
    /* margin-right: 0.75rem; */
  }
  .product-feature.responsive:last-child {
    margin-right: 0;
  }

  @media screen and (min-width: 721px) {
    /* .product-feature.responsive {
      display: flex;
    } */

    .product-features {
      margin-top: 0;
    }
  }

  /* @media screen and (min-width: 480px) { */
  /* Experimental */
  .product-features.horizontal.columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    display: block;
    column-count: 2;
    orphans: 100;
    /* gap: 0.125rem 0.5rem; */
    column-gap: 0.5rem;
  }
  .product-features.horizontal.columns .product-feature {
    margin-bottom: 0.5em;
    line-height: 1.5em;
  }
  .product-features.horizontal.columns .product-feature .answer {
    text-wrap: pretty;
  }
  /* } */
</style>
