<script lang="ts">
  import ArrowLeft from "../icons/ArrowLeft.svelte"
  import ArrowRight from "../icons/ArrowRight.svelte"
  import { t } from "../lang/i18n"
  import Button from "./Button.svelte"
  import Bounded from "./Bounded.svelte"
  import { navigationContext } from "../stores/navigation"
  import Loader from "../icons/Loader.svelte"

  export let hideNext: boolean = false
  export let hidePrev: boolean = false
  export let nextDisabled: boolean = false
  export let disableSticky: boolean = false
  export let onNext: () => void = () => {}
  export let onPrev: () => void = () => {}
</script>

<div class="bottom-nav max-app-width" class:sticky={!disableSticky}>
  {#if !hidePrev}
    <Button variant="navigation" onClick={onPrev}>
      {#if $navigationContext.loadingPreviousScreen}
        <Loader />
      {:else}
        <ArrowLeft />
      {/if}
      <span> {$t("prev")}</span>
    </Button>
  {:else}<div />
  {/if}
  {#if !hideNext || $navigationContext.loadingNextScreen}
    <span class="next-btn">
      <Button variant="primary" onClick={onNext} disabled={nextDisabled || $navigationContext.loadingNextScreen}>
        <span> {$t("next")}</span>
        {#if $navigationContext.loadingNextScreen}
          <Loader />
        {:else}
          <ArrowRight />
        {/if}
      </Button>
    </span>
  {/if}
</div>

<style>
  .bottom-nav {
    margin-top: 2rem;
    /* padding: 0 1rem; */
    min-height: 4rem;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sticky {
    position: sticky;
    bottom: 0rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    z-index: 100;
    background-color: var(--plane-color, #fff);
  }
</style>
