import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Toon meer",
  showLess: "Toon minder",
  bestMatch: "Beste match",
  view: "Bekijk",
  customAction: "In winkelwagen",
  notFound: "404: niet gevonden",
  reset: "Opnieuw beginnen",
  close: "Sluiten",
  noAdviceTitle:
    "Op dit moment zijn er geen producten beschikbaar die volledig bij jouw voorkeuren passen.",
  noAdviceMessage: "Pas je voorkeuren aan, of probeer het later nog eens.",
  prev: "Vorige",
  next: "Volgende",
  backToAdvice: "Terug naar advies",
  dontKnow: "Sla over",
  email: "E-mailadres",
  send: "Verstuur",
  emailError: "Voer een geldig e-mailadres in",
  readMore: "Lees meer",
  selectAnAnswer: "Selecteer een antwoord",
  answerNotListed: "Mijn antwoord staat er niet tussen",
  whyThisProductForMe: "Waarom past dit bij mij?",
  alternative: "alternatief",
  alternatives: "alternatieven",
  seeAlso: "Bekijk ook",
}

export default translations
