<script lang="ts">
  import type { DrilldownOption } from "../../types"
  import DrilldownSelect from "../../ui/DrilldownSelect.svelte"
  import Drilldown from "../../Drilldown"

  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
  })

  export let levelLabels: string[] = []
  export let options: DrilldownOption[] = []
  export let value: string | null = null
  export let onChange: (answer: string | null) => void = () => {}

  // export let control: DrilldownControl
  // export let setAttribute: SetAttribute
  // export let getAttribute: GetAttribute

  // let value: ReturnType<typeof getAttribute> = getAttribute(
  //   control.attributeInstanceId
  // )
  // $: value = getAttribute(control.attributeInstanceId)

  const pathToAnswer = Drilldown.pathToAnswer(options, value || "")

  let selection: (DrilldownOption | undefined)[] = [...Array(6).keys()].map(
    (_, i) => {
      return pathToAnswer[i]
    }
  )

  let sort = (options: DrilldownOption[]): DrilldownOption[] =>
    options.sort((a, b) => collator.compare(a.title, b.title))

  let stepOptions: DrilldownOption[][] = []

  $: {
    stepOptions[0] = sort(options)
    selection.forEach((s, i) => {
      stepOptions[i + 1] = s && "answers" in s ? sort(s.answers) : []
    })
  }

  let select = (value: string, options: DrilldownOption[], index: number) => {
    const option = options.find((opt) => opt.title === value)
    if (selection[index]?.title !== option?.title) {
      selection[index] = option
      resetSelection(index + 1)
    }
    const finalAnswer = selection.find((opt) => opt && "id" in opt)
    if (finalAnswer && "id" in finalAnswer) {
      onChange(finalAnswer.id)
    } else if (value != null || value !== "") {
      onChange(null)
    }
  }

  const resetSelection = (index: number) => {
    for (let k = 5; k >= index; k--) selection[k] = undefined
  }
</script>

<div>
  {#each selection as item, i}
    {#if i === 0 || stepOptions[i].length}
      <div class="mb-md">
        <DrilldownSelect
          id="step-{i + 1}"
          label={levelLabels[i] || ""}
          value={item?.title || ""}
          options={stepOptions[i] || []}
          onSelect={(value) => select(value, stepOptions[i], i)}
          onBlur={() => {
            /* do nothing */
          }}
        />
      </div>
    {/if}
  {/each}
</div>
