import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Mehr zeigen",
  showLess: "Weniger anzeigen",
  bestMatch: "Bester Treffer",
  view: "Siehe",
  customAction: "",
  notFound: "404: nicht gefunden",
  reset: "Neu anfangen",
  close: "Schließen",
  noAdviceTitle:
    "Derzeit haben wir keine Produkte verfügbar, die vollständig Ihren Vorlieben entsprechen.",
  noAdviceMessage:
    "Bitte passen Sie Ihre Präferenzen an, oder versuchen Sie es später noch einmal.",
  prev: "Vorherige",
  next: "Weiter",
  backToAdvice: "Zurück zur Beratung",
  dontKnow: "Überspringen",
  email: "E-Mail-Adresse",
  send: "Senden",
  emailError: "Geben sie eine gültige E-Mail-Adresse an",
  readMore: "Weiter lesen",
  selectAnAnswer: "Wählen Sie eine Antwort aus",
  answerNotListed: "Meine Antwort ist nicht aufgeführt",
  whyThisProductForMe: "Warum dieser Rat?",
  alternative: "Alternative",
  alternatives: "Alternativen",
  seeAlso: "Auch anschauen",
}

export default translations
