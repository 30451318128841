import { ElmApp as ElmModule } from "~/elm-tsx-wrappers/ElmApp"
import React from "react"
import Elm from "./ElmWrapper"
import { useAuth } from "~/context/auth"
import { EnvVar, envVar } from "~/utils/environment"
import { useDispatch } from "react-redux"
import { createNewAdvisor } from "~/actions/advisors"
import { useHistory } from "react-router-dom"
import useNavigation from "~/hooks/useNavigation"
import { useFeatureFlags } from "~/hooks/useFeatureFlags"

const ElmNewAdvisor: React.FC = (props) => {
  const [ports, setPorts] = React.useState<any>()
  const { token, organisationId } = useAuth()
  const apiUrl = envVar(EnvVar.ADVISOR_API_URL) as string
  const dispatch = useDispatch()
  const history = useHistory()
  const auth = useAuth()
  const { toAdvisors } = useNavigation()
  const { featureFlags } = useFeatureFlags()

  React.useEffect(() => {
    if (ports) {
      const createAdvisorHandler = (obj) => {
        const {
          name,
          productLabel,
          emoji,
          language,
          conversation,
          styleTemplateId,
        } = obj

        dispatch(
          createNewAdvisor(
            "request",
            {
              name,
              productLabel,
              icon: emoji,
              locales: [language],
              styleTemplateId: styleTemplateId,
            },
            {
              context: {
                token: auth.token!!,
                organisationId: auth.organisationId,
              },
              history: history,
            },
            conversation
          )
        )
      }

      ports.createAdvisor.subscribe(createAdvisorHandler)
      ports.cancelCreation.subscribe(toAdvisors)
      return () => {
        ports.createAdvisor.unsubscribe(createAdvisorHandler)
        ports.cancelCreation.unsubscribe(toAdvisors)
      }
    }
  }, [auth.organisationId, auth.token, dispatch, history, ports, toAdvisors])

  return (
    // @ts-ignore
    <Elm
      // @ts-ignore
      src={ElmModule.NewAdvisor}
      flags={{
        authToken: token,
        organisationId: organisationId.orgId,
        apiUrl: apiUrl,
        globalStylingEnabled: featureFlags.globalStylingEnabled,
      }}
      ports={setPorts}
    />
  )
}

export default ElmNewAdvisor
