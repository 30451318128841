import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Visa mer",
  showLess: "Visa mindre",
  bestMatch: "Bästa matchningen",
  view: "Se",
  customAction: "",
  notFound: "404: ej Hittad",
  reset: "Börja om",
  close: "Stänga",
  noAdviceTitle:
    "För närvarande finns det inga tillgängliga produkter som helt matchar dina preferenser.",
  noAdviceMessage: "Du kan ändra dina inställningar eller försöka igen senare.",
  prev: "Föregående",
  next: "Nästa",
  backToAdvice: "Åter till råd",
  dontKnow: "Skippa",
  email: "E-postadress",
  send: "Skicka",
  emailError: "Vänligen ange en giltig e-postadress",
  readMore: "Läs mer",
  selectAnAnswer: "välj ett svar",
  answerNotListed: "Mitt svar finns inte med",
  whyThisProductForMe: "Varför detta råd?",
  alternative: "alternativ",
  alternatives: "alternativ",
  seeAlso: "Titta också på",
}

export default translations
