<svg
  xmlns="http://www.w3.org/2000/svg"
  width="19"
  height="12"
  viewBox="0 0 19 12"
>
  <path
    fill="none"
    fill-rule="evenodd"
    stroke="currentColor"
    d="M18.13 6.483l-5.604 4.795a.487.487 0 0 1-.328.117.487.487 0 0
    1-.328-.117.358.358 0 0 1 0-.562L16.672 6.6H1.474a.5.5 0 0
    1-.337-.117.366.366 0 0 1 0-.57.5.5 0 0 1 .337-.118h15.198L11.87
    1.68a.358.358 0 0 1 0-.562.372.372 0 0 1 .15-.09.587.587 0 0 1 .356 0
    .372.372 0 0 1 .15.09l5.604 4.796a.362.362 0 0 1 .137.285.362.362 0 0
    1-.137.285z"
  />
</svg>
