<script lang="ts">
  export let unbound: boolean = false
</script>

<div class="max-app-width" class:is-bound={!unbound} class:is-unbound={unbound}>
  <slot></slot>
</div>

<style>
  div {
    /* margin-left: auto;
    margin-right: auto; */
    /* padding-left: 1rem;
    padding-right: 1rem; */
    width: 100%;
    box-sizing: border-box;
  }
</style>
