import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Vise mer",
  showLess: "Vis mindre",
  bestMatch: "Beste match",
  view: "Se",
  customAction: "",
  notFound: "404: ikke funnet",
  reset: "Begynne på nytt",
  close: "Lukk",
  noAdviceTitle:
    "For øyeblikket er det ingen tilgjengelige produkter som fullt ut samsvarer med dine preferanser.",
  noAdviceMessage:
    "Du kan endre innstillingene dine, eller prøve igjen senere.",
  prev: "Forrige",
  next: "Neste",
  backToAdvice: "Tilbake til råd",
  dontKnow: "Hopp over",
  email: "Epostadresse",
  send: "Send",
  emailError: "Vennligst skriv inn en gyldig e-post adresse",
  readMore: "Les mer",
  selectAnAnswer: "Velg et svar",
  answerNotListed: "Svaret mitt er ikke oppført",
  whyThisProductForMe: "Hvorfor dette rådet?",
  alternative: "alternativ",
  alternatives: "alternativer",
  seeAlso: "Også se på",
}

export default translations
