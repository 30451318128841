  <div class="advice-page-top-header max-app-width">
    <slot name="top-header"></slot>
  </div>
  {#if $$slots["bottom-section"]}
    <div class="advice-page-content">
      <div class="advice-page-results max-app-width">
        <slot name="bottom-section"></slot>
      </div>
    </div>
  {/if}

<style>
  .advice-page-top-header {
    /* background: var(--aiden-advice-page-header-background-color, #fff); */
    --aiden-title-color: var(--aiden-advice-page-header-title-color);
    --aiden-title-font-weight: var(
      --aiden-advice-page-header-title-font-weight
    );
    /* --aiden-title-font: var(--aiden-advice-page-header-title-font); */
    --aiden-title-font-size: var(--aiden-advice-page-header-title-font-size);
    --aiden-regular-text-font-size: var(
      --aiden-advice-page-header-regular-text-font-size
    );
    /* --aiden-regular-text-font: var(
      --aiden-advice-page-header-regular-text-font
    ); */
    --aiden-regular-text-font-weight: var(
      --aiden-advice-page-header-regular-text-font-weight
    );
    --aiden-regular-text-color: var(
      --aiden-advice-page-header-regular-text-color
    );
    --aiden-link-color: var(
      --aiden-advice-page-header-link-color
    );
    --aiden-small-text-font-size: var(
      --aiden-advice-page-header-small-text-font-size
    );
    /* --aiden-small-text-font: var(--aiden-advice-page-header-small-text-font); */
    --aiden-small-text-font-weight: var(
      --aiden-advice-page-header-small-text-font-weight
    );
    --aiden-small-text-color: var(--aiden-advice-page-header-small-text-color);
    --aiden-navigation-button-color: var(
      --aiden-advice-page-bottom-navigation-button-color
    );
    --aiden-navigation-button-background-color-hover: var(
      --aiden-advice-page-bottom-navigation-button-background-color-hover
    );
    --aiden-navigation-button-color-hover: var(
      --aiden-advice-page-bottom-navigation-button-color-hover
    );
    --plane-color: var(--aiden-advice-page-header-background-color, #ebebeb);
    background-color: var(--plane-color);

    padding-bottom: 5rem;
    padding-left: var(--frame-padding, 1rem);
    padding-right: var(--frame-padding, 1rem);
    box-sizing: border-box;
  }
  .advice-page-content {
    --plane-color: var(--aiden-advice-page-bottom-background-color, #ebebeb);
    background: var(--plane-color);
    /* --aiden-app-background-color: var(--aiden-advice-page-bottom-background-color, #ebebeb); */
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: var(--frame-padding, 1rem);
    padding-right: var(--frame-padding, 1rem);
    box-sizing: border-box;
    flex-grow: 1;
  }
  .advice-page-results {
    /* transform: translateY(-6rem); */
    --aiden-navigation-button-color: var(
      --aiden-advice-page-bottom-navigation-button-color
    );
    --aiden-navigation-button-background-color-hover: var(
      --aiden-advice-page-bottom-navigation-button-background-color-hover
    );
    --aiden-navigation-button-color-hover: var(
      --aiden-advice-page-bottom-navigation-button-color-hover
    );
    margin-top: -7rem;
  }
  .advice-page-wrapper {
    display: flex;
    flex-direction: column;
  }
  .advice-page-wrapper > * {
    width: 100%;
  }
</style>
