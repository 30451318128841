import { writable } from "svelte/store"

export const ABTestVariantCheck = {
  isAdviceCustomerInfoVariantB: (activeVariant: string | null): boolean =>
    !!activeVariant && activeVariant === "ADVICE-CUSTOMER-INFO-VARIANT-B",
}

export type ABTestStore = { activeVariant: string | null }

function abTest() {
  const store = writable<ABTestStore>({ activeVariant: null })
  const { subscribe, update } = store

  return {
    subscribe,
    setVariant: (variant: string | null) =>
      update((store) => ({ ...store, activeVariant: variant })),
  }
}

export const abTestStore = abTest()

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// window.Aiden = {
//   reset: () => abTestStore.setVariant(null),

//   adviceCustomerInfoBVariant: () =>
//     abTestStore.setVariant("ADVICE-CUSTOMER-INFO-VARIANT-B"),
// }
