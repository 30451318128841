<script lang="ts">
  export let prefix: string | undefined
  export let suffix: string | undefined
  export let price: string
  export let salePrice: string | undefined = undefined

  let actualPrice = salePrice ? salePrice : price
  let previousPrice = salePrice ? price : undefined
  $: actualPrice = salePrice ? salePrice : price
  $: previousPrice = salePrice ? price : undefined
</script>

<div class="product-price">
  <div class="group">
    {#if prefix}
      <span class="prefix">{prefix}</span>
    {/if}
    <span class="price">{actualPrice}</span>
    {#if suffix}
      <span class="suffix">{suffix}</span>
    {/if}
  </div>
  {#if previousPrice}
    <div class="price previous-price">{previousPrice}</div>
  {/if}
</div>

<style>
  .product-price {
    margin-bottom: 0;
    margin-top: 0.25rem;
    /* align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem; */
  }
  .group {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    column-gap: 0.5ch;
    color: var(--aiden-header-text-color);
  }

  .price,
  .prefix,
  .suffix {
    font-family: var(--aiden-product-price-font-family, inherit);
  }

  .price {
    color: var(--aiden-price-color, #1d264d);
    font-weight: var(--aiden-price-font-weight, normal);
    font-size: var(--aiden-price-font-size, 1rem);
  }

  .price.previous-price {
    font-family: var(--aiden-regular-text-font, inherit);
    font-size: var(--aiden-sale-price-font-size, 0.85rem);
    font-weight: var(--aiden-sale-price-font-weight, normal);
    text-decoration: var(--aiden-sale-text-decoration, line-through);
    color: var(--aiden-sale-price-color, #a3a3a3);
    display: block;
    margin-top: 0.25rem;
  }
  .prefix {
    color: var(--aiden-price-prefix-color, #1d264d);
    font-weight: var(--aiden-price-prefix-font-weight, normal);
    font-size: var(--aiden-price-prefix-font-size, 1rem);
  }

  .suffix {
    color: var(--aiden-price-suffix-color, #1d264d);
    font-weight: var(--aiden-price-suffix-font-weight, normal);
    font-size: var(--aiden-price-suffix-font-size, 1rem);
  }
</style>
