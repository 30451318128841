<script lang="ts">
  import Page from "../app/Page.svelte"
  import type { Page as PageT } from "../app/toPage"

  const page: PageT = {
    type: "start-page",
    screenId: "string",
    title: "Start page title",
    text: "Start page intro text. Ipsum Mattis Nibh Euismod Tortor [with a link.]()",
    additionalText: "",
    buttonText: "Start",
    legacyShowOnlyButton: false,
  }
</script>

<Page
  {page}
  getAttribute={() => null}
  setAttribute={() => {}}
  optionsFor={() => Promise.resolve([])}
  hidePrev
  internalAnalytics={() => {}}
  customerAnalytics={{
    productClick: () => {},
    customerInfoSubmitted: () => {},
    infoPageCustomerInfoSubmitted: () => {},
    noResultsCustomerInfoSubmitted: () => {},
  }}
></Page>
