<script lang="ts">
  import { marked, Renderer } from "marked"

  export let source: string
  export let allowed: "default" | "links-only" | "extended"

  const defaultRenderers = new Renderer()
  const text = defaultRenderers.text
  const allTextRenderers = {
    text: text,
    paragraph: text,
    em: text,
    strong: text,
    hr: () => "",
    blockquote: text,
    del: text,
    link: text,
    image: text,
    table: text,
    tablerow: text,
    tablecell: text,
    list: text,
    listitem: text,
    heading: text,
    codespan: text,
    code: text,
    html: text,
  }

  const linkRenderer = function (href: string, title: string, text: string) {
    return `<a target="_blank" href="${href}" title="${title}" onclick="event.stopPropagation()">${text}</a>`
  }

  const toRenderers = () => {
    if (allowed === "links-only") {
      return {
        ...allTextRenderers,
        link: linkRenderer,
      }
    } else if (allowed === "extended") {
      return {
        ...allTextRenderers,
        paragraph: defaultRenderers.paragraph,
        em: defaultRenderers.em,
        strong: defaultRenderers.strong,
        link: linkRenderer,
        list: defaultRenderers.list,
        listitem: defaultRenderers.listitem,
      }
    } else if (allowed === "default") {
      return {
        ...allTextRenderers,
        paragraph: defaultRenderers.paragraph,
        em: defaultRenderers.em,
        strong: defaultRenderers.strong,
        link: linkRenderer,
      }
    }
  }

  marked.use({ renderer: toRenderers() })
</script>

<div class="markdown">
  {@html marked.parse(source)}
</div>

<style>
  .markdown :global(p:empty) {
    display: none;
  }
  .markdown :global(p:first-child) {
    margin-top: 0;
  }
  .markdown :global(p:last-child) {
    margin-bottom: 0;
  }

  div :global(a),
  div :global(a:visited) {
    color: var(--aiden-text-color);
  }
  div :global(ol),
  div :global(ul) {
    padding-left: 1.25rem;
  }
</style>
