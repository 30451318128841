<script lang="ts">
  import Layout from "../ui/Layout.svelte"
  import Bounded from "../ui/Bounded.svelte"
  import TopHeader from "../ui/TopHeader.svelte"
  import Plane from "../ui/Plane.svelte"
  import DrilldownSelect from "../ui/DrilldownSelect.svelte"
</script>

<div class="aiden-screen-content">
  <Bounded>
    <TopHeader
      title="Drilldown question title"
      text="Drilldown question intro text. Ipsum Mattis Nibh Euismod Tortor"
    />
  </Bounded>
  <Bounded>
    <Plane>
      <Layout>
        <DrilldownSelect label="Level 1" value="" />
        <DrilldownSelect label="Level 2" value="" />
        <DrilldownSelect label="Level 3" value="" />
      </Layout>
    </Plane>
  </Bounded>
  <slot></slot>
</div>
