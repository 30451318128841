import type { Translations } from "../types"

const translations: Translations = {
  showMore: "Vis mere",
  showLess: "Vis mindre",
  bestMatch: "Bedste match",
  view: "Udsigt",
  customAction: "",
  notFound: "404: Ikke fundet",
  reset: "Start forfra",
  close: "Lukke",
  noAdviceTitle:
    "Vi har i øjeblikket ingen produkter tilgængelige, der fuldstændigt matcher dine præferencer.",
  noAdviceMessage: "Du kan ændre dine præferencer, eller prøv igen senere.",
  prev: "Forrige",
  next: "Næste",
  backToAdvice: "Tilbage til råd",
  dontKnow: "Springe over",
  email: "Email addresse",
  send: "Sende",
  emailError: "Indtast en gyldig email addresse",
  readMore: "Læs mere",
  selectAnAnswer: "Vælg et svar",
  answerNotListed: "Mit svar er ikke opført",
  whyThisProductForMe: "Hvorfor dette råd?",
  alternative: "alternativ",
  alternatives: "alternativer",
  seeAlso: "Også se på",
}

export default translations
