import type { DrilldownOption } from "./types"

const findPathToAnswer =
  (answerId: string) =>
  (option: DrilldownOption): DrilldownOption[] => {
    if ("id" in option && option.id === answerId) {
      return [option]
    } else if ("answers" in option) {
      const path = option.answers.flatMap(findPathToAnswer(answerId))
      if (path.length) {
        return [option, ...path]
      } else {
        return []
      }
    } else {
      return []
    }
  }

export default {
  pathToAnswer: (options: DrilldownOption[], answerId: string) =>
    options.map(findPathToAnswer(answerId)).find((paths) => !!paths.length) ||
    [],
}
