<svelte:options customElement="aiden-regular-text" />

<script lang="ts">
  import Markdown from "../ui/Markdown.svelte"
  import RegularText from "../ui/RegularText.svelte"

  export let markdown: string = ""
</script>

<RegularText>
  {#if markdown}
    <Markdown source={markdown} allowed="extended" />
  {:else}
    <slot />
  {/if}
</RegularText>
