<svelte:options customElement="aiden-numeric-input" />

<script lang="ts">
  import NumberInput from "../ui/NumberInput.svelte"
  import Plane from "../ui/Plane.svelte"

  export let label: string = ""
</script>

<div>
<Plane>
  <NumberInput
    {label}
    value={null}
    onChange={() => {}}
    allowDecimals={true}
    info={{position: "suffix", label: "Suffix"}}
  />
</Plane>
</div>
<style>
  div :global(*) {
    cursor: default !important;
  }
</style>
